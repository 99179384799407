*{
    padding: 0;
    margin: 0;
    outline: 0;
}
:root{
   
        --primary-color:#102B3F;
        --secondary-color: #FCEDF0;
        --hint: #ff3d64;
        --green:#1C9C76;
    }

.footer{
    height: 10vh;
    width: 100%;
    background-color: var(--primary-color);
    color: white;
}
.wrapper{
    width: 70%;
    height: 100%;
    margin: 0 auto;
   position: relative;
   
}
@media screen and (max-width:768px){
.wrapper{
    width: 90%;
}

}
.copyright{
    position: absolute;
  
  
    bottom: 5px;
    left: 0;
}
@media screen and (max-width:768px){
    .copyright{
        font-size: 70%;
        
    }
}
.copyright span{
    color: var(--green);
}
.social{
    position: absolute;
    right: 10%;
    bottom: 5px;
     
}

.git{
    position:relative;
    left: 0%;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color:white;
   
}

.twitter{
    position: relative;
    left: 50%;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color:white;
}
.linked{
    position: relative;
    left: 100%;
    cursor: pointer;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color:white;
}

