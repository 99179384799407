* {
  padding: 0;
  margin: 0;
  outline: 0;
}
:root {
  --primary-color: #102b3f;
  --secondary-color: #fcedf0;
  --hint: #ff3d64;
  --green: #1c9c76;
}

.blogList h1 {
  font-weight: 400;
  margin: 1rem;
  color: var(--green);
  margin-top: 3rem;
}
.blogList {
  width: 60%;
  margin: 1rem auto;
}

.blogList h3 {
  font-weight: 400;
  margin: 1rem;
}
.contactParentB {
  width: 80%;
  margin: 1rem;
  padding-bottom: 2rem;
}

.titleB {
  font-size: 1rem;
}
.contactCardB small {
  font-size: 12px;
  color: var(--primary-color);
}
.contactCardB p {
  width: 80%;
  margin: 1rem 0;
  margin-bottom: 2rem;
}

.liveProjectB {
  /* background-color: var(--green); */
  text-decoration: none;
  color: black;
  border-radius: 1rem;
  padding: 4px 10px;
  margin-right: 0.5rem;
}
.liveProjectB:hover {
  background-color: var(--hint);
  cursor: pointer;
  /* color: white; */
  transition: all 0.2s ease-in-out;
}

.titleLabel {
  color: yellow;
  display: block;
}
.contactLink {
  text-decoration: none;
  color: black;
}
.contactCard {
  z-index: 2;
  cursor: pointer;
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  margin: 1rem 2rem;
  width: 55%;
  margin-left: 0.25rem;
}
.contactCard:hover {
  margin-left: 30px;
  transition: all 0.4s cubic-bezier(0.2, 0.6, 0.2, 1);
}
.contactText {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green);
}
.contactLabel {
  color: var(--hint);
}
.cardContainer {
  display: flex;
  margin: auto;
  position: relative;
}
.marker {
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translate(-50%, -50%);

  background-color: var(--primary-color);
  width: 2px;
  top: 50%;
  height: 30%;
  border-radius: 0.5rem;
  z-index: 0;
  color: transparent;
}
.marker:hover {
  display: block;
}
.contactIcon {
  display: none;
}
h3 {
  text-align: justify;
}
@media screen and (max-width: 1055px) {
  .contactCard {
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
    text-align: center;
    width: 70%;
  }
  .contactLabel {
    display: none;
  }
  .marker {
    display: none;
  }
  .contactIcon {
    display: block;
    color: var(--hint);
    margin-bottom: 0.25rem;
  }
  .contactText {
    font-size: 1rem;
  }
}
