*{
    padding: 0;
    margin: 0;
    outline: 0;
}
:root{
   
        --primary-color:#102B3F;
        --secondary-color: #FCEDF0;
        --hint: #ff3d64;
        --green:#1C9C76;
    }

.skills{
 width: 80%;
 margin: 0 auto;
 padding-bottom: 2rem;
 margin-top: 2rem;

}
.skills h2 span{
    color: var(--hint);
}
.skills h2{
    margin-bottom: 2rem;
}
.skillParent {
    max-width: 1000px;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr  1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
    margin-bottom: 2rem;
  
}
@media screen and (max-width:1000px){
   .skillParent{
    grid-template-columns: 1fr 1fr 1fr;
   }
}

@media screen and (max-width:768px){
   .skillParent{
    grid-template-columns:  1fr 1fr;
    padding:0 20px;
}
}


.skillCard{
   
    width: fit-content;
    /* background-color: white; */
    padding: 1rem;
    height: 100px;
    margin: 1rem auto;
    margin-bottom: 2rem;
    align-items: center;
    
}
.skillCard img{
    display: block;
    height: 75%;
    width: 100%;
    margin: auto;
    justify-self: center;

}
.skillCard img:hover{
    transform: scale(1.02);
    transition: all 0.2s  ease-in-out;
    cursor: pointer;
}
.caption{
    margin-top: auto;
    font-size: 1rem;
    font-weight: 500;
   text-align: center;
    width: fit-content;
    margin: 1rem auto;

}

.btnMore{
    
        color: white;
        background-color: var(--hint);
        border-radius: 1rem;
        padding: 0.7rem 1rem; 
        transition: all 0.2s ease-in-out;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width:fit-content;
        cursor: pointer;
        justify-content: center;
        display: flex;
        border: none;
        font-size: 0.9rem;
}
.top{
    width: fit-content;
    margin: 1rem auto;
    margin-top: 3rem;
    justify-content: center;
    cursor: pointer;
}

.top span{
    display: block;
    margin-left: -3px;
    
}