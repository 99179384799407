* {
  padding: 0;
  margin: 0;
  outline: 0;
}
:root {
  --primary-color: #102b3f;
  --secondary-color: #fcedf0;
  --hint: #ff3d64;
}

.navParent {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  text-align: center;
}
.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--secondary-color);
  background-color: var(--primary-color);

  width: 90%;
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.navigation h2 {
  display: inline;
  width: 60%;
  text-align: left;
  /* margin: 0 auto; */
  font-size: 1.3rem;
  color: white;
  cursor: pointer;
  letter-spacing: 0.5px;
}
.navigation h2 span {
  color: var(--hint);
  margin-left: -0.3rem;
  font-size: 1.5rem;
}

.navigation div {
  margin: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.navigation li {
  list-style: none;
  display: inline;
  /* margin: 1rem 1rem; */
  font-weight: 200;
  font-size: 1.2rem;
}
.navigation li:hover {
  text-decoration: underline;
}

.navigation .link {
  color: var(--secondary-color);
  margin: 0.5rem;
  text-decoration: none;
  cursor: pointer;
}
.nav-pills {
  display: flex;
  margin: auto 0;
}

.active a {
  font-weight: bold;
}

@media screen and (max-width: 670px) {
  .navigation {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }
  .navigation h2 {
    text-align: center;
    margin: auto;
    width: 100%;
  }
  .navigation ul {
    margin-top: 0.25rem;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
