*{
    padding: 0;
    margin: 0;
    outline: 0;
}
:root{
   
    --primary-color:#102B3F;
    --secondary-color: #FCEDF0;
    --hint: #ff3d64;
    --green:#1C9C76;
}

.blogList h1{
    font-weight: 400;
    margin: 1rem ;
    color: var(--green);
    margin-top: 3rem;
}
.blogList{
    width: 60%;
    margin: 1rem auto;
}

.blogList h3{
    font-weight: 400;
    margin: 1rem;
}
.listParentB{
    width: 80%;
    margin: 1rem;
    padding-bottom: 2rem;
   
}
.listCardB{
    background-color: white;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
        transition: all 0.2s ease-in-out;
        margin: 2rem auto;
        padding-bottom: 2rem;
        width: 100%;
}
.listCardB:hover{
    transform: scale(1.05);
    transition: all 0.2s  ease-in-out;
}
.titleB{
    font-size: 1rem;

}
.listCardB small{
    font-size: 12px;
    color: var(--primary-color);
    
}
.listCardB p{
    width: 80%;
    margin: 1rem 0;
    margin-bottom: 2rem;
}

.liveProjectB{
    background-color: var(--green);
    text-decoration: none;
    color: white;
    border-radius: 1rem;
    padding: 4px 10px;
    margin-right: 0.5rem;
    
}
.liveProjectB:hover{
    background-color: var(--hint);
    cursor: pointer;
    color: white;
    transition: all 0.2s ease-in-out;
}
