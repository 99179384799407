* {
  padding: 0;
  margin: 0;
  outline: 0;
}
:root {
  --primary-color: #102b3f;
  --secondary-color: #fcedf0;
  --hint: #ff3d64;
  --green: #1c9c76;
}

.introParent {
  display: flex;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 90%;
  gap: 1rem;
  justify-content: space-between;
}
.text {
  position: relative;
  width: 45%;
  float: left;
  /* height: 100%; */
  margin-top: 2rem;
  font-size: 1rem;
  /* flex: 1; Grow and shrink as needed */
  min-width: 300px; /* Prevent shrinking below 300px */
}

/* Carousel Container */
.carousel {
  position: relative;
  float: right;
  width: 45%;
  margin: 0 2rem; /* Center the carousel */
  flex: 1; /* Grow and shrink as needed */
  max-width: 400px; /* Limit maximum width of the carousel */
  min-width: 400px; /* Prevent shrinking below 300px */
}

/* Carousel Item */
.carouselItem {
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* background: #fff; */
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
  display: flex !important;
  width: 85% !important;
  margin: 1rem auto;
  flex-direction: column;
}

.carouselItem:hover {
  transform: scale(1.05);
}

/* Carousel Image */
.carouselImage {
  max-width: 100%;
  /* width: 50vh; */
  height: 20vh;
  border-radius: 10px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

/* Carousel Title */
.carouselItem h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

/* Carousel Description */
.carouselItem p {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Carousel Link */
.carouselLink {
  width: 20%;
  margin: 0 auto;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: var(--primary-color);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

/* Slick Dots */
.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: var(--green) !important;
}

.slick-dots li.slick-active button:before {
  color: #0056b3;
}

/* Slick Arrows */
.slick-prev,
.slick-next {
  z-index: 10;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border-radius: 50%;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.slick-next:before,
.slick-prev:before {
  color: var(--hint) !important;
  font-size: 2rem;
}

.slick-prev:hover,
.slick-next:hover {
  background-color: #0056b3;
}

.slick-prev:before,
.slick-next:before {
  content: "";
}

/* @media screen and (max-width: 700px) {
  .text {
    font-size: 1.1rem;
  }
} */
.text h1 {
  color: var(--green);
  display: block;
  font-weight: 500;
}
.text h2 {
  font-weight: 500;
  display: inline;
}
.text span {
  color: var(--hint);
  font-weight: 500;
  font-size: 1rem;
}
.text p {
  font-weight: 400;
  max-width: 500px;
  margin-top: 0.4rem;
}

.imj {
  position: relative;
  width: 50%;
  float: right;
  height: 100%;
}
.imj img {
  height: 100%;
  width: 100%;
}

.project {
  width: 90%;
  margin: 1rem auto;
  height: auto;
  padding-bottom: 1rem;
}

.project h2 {
  width: fit-content;
  display: block;
}
.project h2 span {
  color: var(--hint);
}
.cardParent {
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
}

@media screen and (max-width: 1000px) {
  .cardParent {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .cardParent {
    grid-template-columns: 1fr;
    padding: 0px;
  }
}

.card {
  background-color: white;
  /* padding:0.5rem; */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  border-radius: 1rem;
  /* max-height: 90%; */
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  justify-self: center;
}
.card h3{
    text-align: left;
}
.card:hover {
  transform: scale(1.02);
  transition: all 0.2s ease-in-out;
}

.card small {
  margin-bottom: 0.2rem;
}
.card p {
  font-size: 0.92rem;
  margin-bottom: 0.5rem;
}
.proj-preview {
  position: relative;
  height: 90%;
  width: 100%; min-height: 270px;
display: flex;
align-items: center;
}
.proj-preview img {
  width: 100%;
  height: 100%;
 
  margin: auto 0;
}
.techStack {
  width: 80%;
  height: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 8px;
  justify-self: center;
  justify-content: center;
}
.techStack h6 {
  color: white;
  background-color: var(--primary-color);
  text-align: center;
  font-size: 0.75rem;
  border-radius: 1rem;
  width: fit-content;
  padding: 0.2rem 0.5rem;
}

.code {
  width: 100%;
  margin-top: 0.5rem;
}
.code a {
  text-decoration: none;
}

.live {
  background-color: var(--green);
  border-radius: 1rem;
  color: white;
  border: none;
  padding: 0.3rem;
  margin-right: 1rem;
  font-size: 0.8rem;
}
.live:hover {
  background-color: var(--hint);
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
}
.source {
  background-color: transparent;
  border-radius: 1rem;
  color: var(--green);
  border: 1px solid var(--green);
  padding: 0.3rem;
  margin-right: 1rem;
  font-size: 0.8rem;
}

.source:hover {
  background-color: var(--green);
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
}

.viewA {
  text-decoration: none;
  color: white;
  background-color: var(--hint);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  justify-content: center;
  display: flex;
}

.viewA:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.blog {
  width: 80%;
  margin: 1rem auto;
  height: auto;
  padding-bottom: 1rem;
}

.blog h2 {
  width: fit-content;
  display: block;
}
.blog h2 span {
  color: var(--hint);
}
.cardParentB {
  width: 100%;
  margin: 1rem 0;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
}

@media screen and (max-width: 1000px) {
  .cardParentB {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .cardParentB {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.cardB {
  background-color: white;
  /* padding:0.5rem; */
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  border-radius: 1rem;
  /* max-height: 90%; */
  padding: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  justify-self: center;
}

.cardB:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}

.cardB small {
  margin-bottom: 0.2rem;
}
.cardB p {
  font-size: 0.92rem;
  margin-bottom: 0.5rem;
}

.codeB {
  width: 100%;
  margin-top: 0.5rem;
}

.liveB {
  background-color: var(--green);
  border-radius: 1rem;
  color: white;
  border: none;
  padding: 4px 12px;
  margin-right: 1rem;
  font-size: 0.8rem;
  text-decoration: none;
}
.liveB:hover {
  background-color: var(--hint);
  cursor: pointer;
  color: white;
  transition: all 0.2s ease-in-out;
}

.viewB {
  text-decoration: none;
  color: white;
  background-color: var(--hint);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: fit-content;
  cursor: pointer;
  justify-content: center;
  display: flex;
}

.viewB:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
/* Responsive Design */
@media (max-width: 890px) {
  .introParent {
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Center align the content for smaller screens */
    width: 90%;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3rem;
  }

  .text {
    flex: none; /* Disable flex-grow/shrink */
    width: 100%; /* Take up full width */
    margin-bottom: 20px; /* Add spacing below the text section */
    text-align: center;
  }
  .text h2 {
    display: block;
  }
  .text span {
    display: block;
    width: 80%;
    margin: 0 auto;
    font-weight: 500;
  }
  .text p {
    margin: 0 auto;
  }
  .carousel {
    flex: none; /* Disable flex-grow/shrink */
    width: 100%; /* Take up full width */
  }
}
